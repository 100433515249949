import React from 'react'
import { Link } from 'gatsby'

// import logo from '../img/logo.webp'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li className="has-text-centered">
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li className="has-text-centered">
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li className="has-text-centered">
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                  <li className="has-text-centered">
                      <Link className="navbar-item" to="/products">
                        Products
                      </Link>
                    </li>
                    <li className="has-text-centered">
                      <Link className="navbar-item" to="/checkout">
                        Cart
                      </Link>
                    </li>
                    <li className="has-text-centered">
                      <Link className="navbar-item" to="/admin/" target="_blank" rel="noopener noreferrer">
                        Admin
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section className="social">
                  <div style={{ marginTop: '1.5em' }}>
                <a title="facebook" href="https://facebook.com/Kailash.org.uk/">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com/kailash.org.uk/">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
