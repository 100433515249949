import React from 'react'
import ResponsiveMenu from 'react-responsive-navbar';
import styled from 'styled-components';
import { Link } from 'gatsby'
import logo from '../img/logo.webp'
import { CartContextConsumer } from '../contexts/CartContext';
import { FaRegWindowClose } from 'react-icons/fa';

const Menu = styled.div`
  ul {
    padding: 0;
  }
  li {
    display: block;
    list-style-type: none;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    color: #B77729;
    &:hover {
      color: black;
    }
  }
  @media (min-width: 501px) {
    li {
      display: inline-block;
    }
  }
  @media (max-width: 500px) {
    padding: 10px 0;
    li {
      display: block;
      margin-left: 0;
    }
  }
`;

const Navbar = class extends React.Component {
  constructor() {
    super()
    this.state = {
      active: false,
      navBarActiveClass: '',

    }
  }

  toggleHamburger = () => {
    console.log('toggleHamburger')
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container iscentered" style={ { marginTop: '0.5em' } }>
          <ResponsiveMenu
            menuOpenButton={<img src={logo} alt="Kailash" style={{ width: '88px' }} />}
            menuCloseButton={<FaRegWindowClose size={30} color="#B77729" style={ { marginTop: '0.5em' } }/>}
            changeMenuOn="500px"
            largeMenuClassName="large-menu"
            smallMenuClassName="small-menu"
            menu={
              <Menu>
                <ul>
                  <li>
                    <Link className="navbar-item" to="/" activeClassName="navbar-item active">
                      <img src={logo} alt="Kailash" style={{ width: '88px' }} />
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/about" activeClassName="navbar-item active">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/products" activeClassName="navbar-item active">
                      Products
                    </Link>
                  </li>
                  <li>
                    <CartContextConsumer>
                      {context => (
                        <Link className="navbar-item" to="/checkout" activeClassName="navbar-item active">
                          Cart{(context.totalItems > 0) ? (<span> ({context.totalItems})</span>) : ''}
                        </Link>
                      )}
                    </CartContextConsumer>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/faq" activeClassName="navbar-item active">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/contact" activeClassName="navbar-item active">
                      Contact
                    </Link>
                  </li>
                </ul>
              </Menu>
            }
          />
        </div>
      </nav>
    )
  }
}

export default Navbar
