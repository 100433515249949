import { default as React } from 'react';
import { default as styled } from 'styled-components';

const TopRightRibbon = styled.div`
    width: 300px;
    padding: 16px;
    position: absolute;
    text-align: center;
    color: #000;
    top: 25px;
    right: -75px;
    transform: rotate(45deg);
    background-color: #BB772E;
    z-index: 50;
`;

export function Ribbon({ props }) {

    return (
        <>
            <TopRightRibbon>Free postage<br/>on orders over £50</TopRightRibbon>
        </>
    );
}

export default Ribbon;