import React, { createContext, useState } from 'react';
// import { graphql, useStaticQuery } from 'gatsby';
import { productsStatic } from '../components/productsStatic';
const { Provider, Consumer } = createContext();

export const ProductsContextProvider = ({children}) => {

    class Product {
        constructor(node) {
            // console.log('new Product');
            this.variants = node.variants;
            // console.dir(this.variants);
            this.node = node;
            this._price = parseFloat(this.node.price);
        }

        getVariant(variant) {
            // console.log(`ProductsContext get variant: ${variant}`)
            return this.variants.find((v) => v.code === variant);
        }

        get price() {
            return this._price;
        }

        get weight() {
            return this.node.weight;
        }

        get name() {
            return this.node.name;
        }

        get title() {
            return this.node.title;
        }
    }
    
    /* const productsDict = productsStatic.reduce((dict, product) => {
        dict[product.sku] = new Product(product);
        return dict;
    }, {}); */
    const dict = Object.keys(productsStatic).reduce((rv, sku) => {
        rv[sku] = new Product(productsStatic[sku]);
        return rv;
    }, {});
    const [products] = useState(dict);
    return (
        <Provider value={ products }>{ children }</Provider>
    )
}

export { Consumer as ProductsContextConsumer };
 
export default ProductsContextProvider;