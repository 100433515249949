import React, { createContext, useState } from 'react';
// import { CartItems } from './CartItems';

import { productsStatic } from '../components/productsStatic';

const CartContext = createContext({
    items: [],
    totalItems: 0,
    totalCost: 0,
    totalWeight: 0,
    addItem: () => {},
    removeItem: () => {},
    increaseItem: () => {},
    decreaseItem: () => {},
    getItemCount: () => {},
    empty: () => {}
});

const { Provider, Consumer } = CartContext;

let round = (num, places) => {
    const x = Math.pow(10, places);
    return Math.round(num * x) / x;
};

export const CartContextProvider = ({children}) => {
    const initialItemsValue = (typeof localStorage !== 'undefined' && localStorage.getItem('cart') !== null) ? JSON.parse(localStorage.getItem('cart')) : [];
    const [items, setItems] = useState(initialItemsValue);
    const itemsCount = (i) => {
        return i.reduce((total, product) => {
            return total + product.quantity;
        }, 0);
    };
    const itemsCost = (i) => {
        return round(i.reduce((total, product) => {
            return total + (productsStatic[product.sku].price * product.quantity);
        }, 0), 2);
    };
    const itemsWeight = (i) => {
        return i.reduce((total, product) => {
        return total + (productsStatic[product.sku].weight * product.quantity);
        }, 0);
    };
    const shippingCostMeth = (cost) => (cost > 50.00) ? 0.00 : 3.00;
    const [totalItems, setTotalItems] = useState(itemsCount(items));
    const [totalCost, setTotalCost] = useState(itemsCost(items) + shippingCostMeth(itemsCost(items)));
    const [totalWeight, setTotalWeight] = useState(itemsWeight(items));
    const [shippingCost, setShippingCost] = useState(shippingCostMeth(itemsCost(items)));

    const save = (newItems) => {
        setItems(newItems);
        setTotalItems(itemsCount(newItems));
        const iCost = itemsCost(newItems);
        const shipCost = shippingCostMeth(iCost);
        setTotalCost(iCost + shipCost);
        setTotalWeight(itemsWeight(newItems));
        setShippingCost(shipCost);
        localStorage.setItem('cart', JSON.stringify(newItems));
    };

    const productIndex = (sku, variant) => {
        return items.findIndex((item) => item.sku === sku && item.variant === variant);
    }

    const isInItems = (sku, variant) => {
        return (productIndex(sku, variant) > -1);
    };

    const addItem = (sku, variant) => {
        if (!isInItems(sku, variant)) {
        const newItems = items.slice(0);
        newItems.push({
            sku,
            variant,
            quantity: 1
        });
        save(newItems);
        } else {
            increaseItem(sku, variant);
        }
    };

    const removeItem = (sku, variant) => {
        const index = productIndex(sku, variant);
        if (index > -1) {
            const newItems = items.slice(0);
            newItems.splice(index, 1);
            save(newItems);
        };
    };

    const increaseItem = (sku, variant) => {
        const index = productIndex(sku, variant);
        if (index > -1) {
            const newItems = items.slice(0);
            newItems[index].quantity++;
            save(newItems);
        }
    }

    const decreaseItem = (sku, variant) => {
        const index = productIndex(sku, variant);
        if (index > -1) {
            const newItems = items.slice(0);
            newItems[index].quantity--;
            if (newItems[index].quantity === 0) {
                removeItem(sku, variant);
            } else {
                save(newItems);
            }
        }
    }

    const getItemCount = (sku, variant) => {
        const index = productIndex(sku, variant);
        if (index > -1) {
            return items[index].quantity;
        }
        return 0;
    }

    const empty = () => {
        if (items.length > 0) save([]);
    };

    return (
        <Provider value={ { totalItems, totalCost, totalWeight, shippingCost, items, addItem, removeItem, increaseItem, decreaseItem, getItemCount, empty } }>{ children }</Provider>
    )
}

export { Consumer as CartContextConsumer };

export { CartContext };